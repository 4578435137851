<template>
  <Layout>
    <BHeader>
      <!-- <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item v-for="(item,index) in headerItem" :key="index">
          <el-input
            v-model="formData.block"
            clearable
            placeholder="订单编号/用户ID"
            @keyup.enter.native="handleSearch"
          />
        </el-form-item>
      </el-form> -->
      <template v-slot:right>
        <div style="width:200px">
          <el-button
            type="primary"
            @click="dialogVisible = true"
          >
            新增
          </el-button>
          <!-- <el-button
            type="primary"
            @click="handleExport"
          >
            导出
          </el-button>
          <h3 style="padding-top:10px">
            总金额(USD) {{ (orderListInfo.priceTotal).toFixed(2) }}
          </h3> -->
        </div>
      </template>
    </BHeader>
    <el-table
      :data="itemInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column
        v-for="(item,index) in tableItem"
        :key="index"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        :formatter="item.formatter"
      />

      <el-table-column
        fixed="right"
        label="操作"
      >
        <template v-slot="{ row }">
          <el-button
            type="primary"
            @click="handleEditItem(row)"
          >
            编辑
          </el-button>
          <!-- <BTextButton
            title="编辑"
            icon="fa fa-pencil"
            @click="handleEditItem(row)"
          /> -->
          <!-- <BTextButton
            :loading="row.loading"
            :title="row.status === 1 ? '启用' : '禁用'"
            :icon="`fa ${row.status === 1 ? 'fa-check-circle' : 'fa-ban'}`"
            @click="handleToggleItem(row)"
          />
          <BTextButton
            :loading="row.deleteLoading"
            title="删除"
            icon="fa fa-trash-o"
            @click="handleDeleteItem(row)"
          /> -->
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="subFormData.id ? '编辑广告位' : '新增广告位'"
      :visible.sync="dialogVisible"
      width="70%"
      @close="handleClose"
    >
      <el-form
        ref="subFormData"
        label-width="120px"
        :model="subFormData"
        @submit.native.prevent
      >
        <el-form-item label="广告位置">
          <el-select
            v-model="subFormData.slot"
            placeholder="请选择"
          >
            <el-option
              v-for="( item,key) in pageObj"
              :key="key"
              :label="item"
              :value="key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="广告类型">
          <el-select
            v-model="subFormData.type"
            placeholder="请选择"
          >
            <el-option
              v-for="( item,key) in typeObj"
              :key="key"
              :label="item"
              :value="key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="外显包:">
          <el-checkbox-group
            v-model="subFormData.app_keys"
          >
            <el-checkbox
              v-for="(item, key) in appKeysNameMap"
              :key="key"
              :label="key"
              @change="clearAppKeysCode(key)"
            >
              {{ item }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <template
          v-if="subFormData.app_keys.length"
        >
          <div
            v-for="(key, item) in appKeysNameMap"
            :key="item"
          >
            <div
              v-if="subFormData.app_keys.indexOf(item) > -1"
            >
              <el-divider />
              <div style="margin:20px 0 30px 120px;font-weight:bold">
                {{ `${key}配置` }}
              </div>
              <el-form-item
                label="广告ID"
                prop="advert_id"
              >
                <el-input v-model="subFormData.settings[item].advert_id" />
              </el-form-item>
              <el-form-item
                v-if="subFormData.settings && subFormData.settings[item]"
                label="广告样式:"
              >
                <el-checkbox-group v-model="subFormData.settings[item].style">
                  <el-checkbox
                    v-for="(item, key) in styleObj"
                    :key="key"
                    :label="key"
                  >
                    {{ item }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <!-- <el-form-item
                label="尺寸"
                prop="size"
              >
                <el-radio-group
                  v-model="subFormData.settings[item].advert_size"
                >
                  <el-radio
                    v-for="(item,key) in AdvertSizeEnum"
                    :key="key"
                    :label="key"
                  >
                    {{ item }}
                  </el-radio>
                </el-radio-group>
              </el-form-item> -->
              <el-form-item
                label="面向国家"
                prop="region_type"
              >
                <el-select
                  v-model="subFormData.settings[item].region_type"
                  @change="clearRegionCode(item)"
                >
                  <el-option
                    v-for="(regionTypeitem, regionTypekey) in regionTypeObj"
                    :key="regionTypekey"
                    :label="regionTypeitem "
                    :value="regionTypekey"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="subFormData.settings[item].region_type !== 'all'"
                label="国家列表"
                prop="region_code"
              >
                <el-input
                  v-model="subFormData.settings[item].region_code"
                />
                <span style="color:red">
                  多个国家使用” , “号隔开
                </span>
              </el-form-item>
              <!-- 新添加 -->
              <el-form-item
                label="面向语言"
                prop="language_type"
              >
                <el-select
                  v-model="subFormData.settings[item].language_type"
                  @change="clearlanguageCode(item)"
                >
                  <el-option
                    v-for="(languageTypeitem, languageTypekey) in languageTypeObj"
                    :key="languageTypekey"
                    :label="languageTypeitem "
                    :value="languageTypekey"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="subFormData.settings[item].language_type != 'all'"
                label="语言配置"
                prop="language_code"
              >
                <el-input
                  v-model="subFormData.settings[item].language_code"
                />
                <span style="color:red">
                  多个语言使用” , “号隔开
                </span>
              </el-form-item>
              <el-form-item
                v-if="subFormData.settings[item].language_type != 'all'"
                label="外显版本"
                prop="version"
              >
                <el-input
                  v-model.number="subFormData.settings[item].version[0]"
                  style="width:91px"
                />
                <span style="padding: 0 6px">-</span>
                <el-input
                  v-model.number="subFormData.settings[item].version[1]"
                  style="width:91px"
                />
              </el-form-item>
            </div>
          </div>
          <el-divider />
        </template>
        <el-form-item
          label="状态"
          prop="status"
        >
          <el-radio-group v-model="subFormData.status">
            <el-radio
              v-for="(item,key) in stateObj"
              :key="key"
              :label="key * 1"
            >
              {{ item }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleClose()">取 消</el-button>
        <el-button
          :loading="addLoading"
          type="primary"
          @click="createAndUpdateAdsense"
        >保 存</el-button>
      </span>
    </el-dialog>
  </Layout>
</template>

<script>
//  createAdsense,
//   updateAdsense
import { getAdsenseList, createAdsense, updateAdsense } from '@/api/adsense'
// import { getAppKeys } from '@/api/blacklist.js'
import { commonRequestGet } from '@/api/commonApi.js'

const pageObj = {
  search: '搜索页',
  detail: '详情页',
  library: 'Library页'
}
const typeObj = {
  google: '原生广告'
}
const stateObj = {
  1: '上架',
  2: '下架'
}
const styleObj = [
  { id: 1, name: '大视频（上）' },
  { id: 2, name: '大视频（下）' },
  { id: 3, name: 'Full视频（内）' },
  { id: 4, name: '左视频右文本' },
  { id: 5, name: '图文竖版（中）' },
  { id: 6, name: '图文横幅（小）' },
  { id: 7, name: '图文横幅（中）' },
  { id: 8, name: '图文横幅（大）' }
]

const regionTypeObj = {
  all: '全部国家',
  display: '部分国家显示',
  hide: '部分国家隐藏'
}
const languageTypeObj = {
  all: '全部语言',
  display: '部分语言显示',
  hide: '部分语言隐藏'
}
export default {
  data () {
    return {
      pageObj,
      typeObj,
      regionTypeObj,
      languageTypeObj,
      stateObj,
      styleObj,
      style: [],
      version: ['', ''],
      itemInfo: {
        list: [],
        loading: false,
        total: 0
      },
      formData: {
        pageNum: 1,
        pageSize: 20
      },
      tableItem: [
        { prop: 'slot', label: '页面', formatter: (row, column, cellValue, index) => pageObj[cellValue] },
        { prop: 'type', label: '广告类型', formatter: (row, column, cellValue, index) => typeObj[cellValue] },
        { prop: 'app_keys', label: '应用名', formatter: this.appKeyFormatter },
        { prop: 'status', label: '状态', formatter: (row, column, cellValue, index) => stateObj[cellValue] }
      ],
      headerItem: [
        {}
      ],
      subFormData: {
        slot: 'search',
        type: 'google',
        status: 2,
        app_keys: [],
        settings: {
        }
      },
      dialogVisible: false,
      addLoading: false,
      appKeysNameMap: []
    }
  },
  watch: {
    'subFormData.app_keys': {
      handler (newValue) {
        newValue.forEach((item) => {
          if (!this.subFormData.settings) this.subFormData.settings = {}
          if (!this.subFormData.settings[item]) {
            this.$set(this.subFormData.settings, item, {
              advert_id: '',
              region_type: 'all',
              region_code: '',
              // advert_size: '',
              // advert_id: '',
              style: [],
              language_type: 'all',
              language_code: '',
              version: [
                '',
                ''
              ]
            })
          }
        })
      },
      immediate: true,
      deep: true
    }
  },
  async created () {
    this.getAppKeys()
    this.getListData()
    this.styleObj = {
      1: '大视频（上）',
      2: '大视频（下）',
      3: 'Full视频（内）',
      4: '左视频右文本',
      5: '图文竖版（中）',
      6: '图文横幅（小）',
      7: '图文横幅（中）',
      8: '图文横幅（大）'
    }
  },
  methods: {
    // getAppKeys () {
    //   getAppKeys({
    //     platform: ''
    //   }).then(res => {
    //     if (res.code === 200) {
    //       const appKeysList = res?.data?.list ?? []
    //       this.appKeysNameMap = appKeysList.reduce((result, item) => {
    //         result[item.appKey] = item.name
    //         return result
    //       }, {})
    //     }
    //   })
    // },
    // 在选择国家all的时候，内容清空
    clearRegionCode (item) {
      console.log(item, '---------')
      if (this.subFormData.settings[item].region_type === 'all') {
        this.subFormData.settings[item].region_code = ''
      }
    },
    // 在选择语言all的时候，内容清空
    clearlanguageCode (item) {
      if (this.subFormData.settings[item].language_type === 'all') {
        this.subFormData.settings[item].language_code = ''
        this.subFormData.settings[item].version = ['', '']
      }
    },
    // 在不选择外显包的时候，内容清空
    clearAppKeysCode (key) {
      if (this.subFormData.app_keys.includes(key)) {
        this.$set(this.subFormData.settings, key, null)
      }
    },
    async getAppKeys () {
      const res = await commonRequestGet('admin/app-review', this.formData)
      // console.log(res, '1111111')
      this.appKeysList = res?.data ?? []
      // 当排序设置为0时，不在应用下拉框内显示
      this.filteredAppKeysList = this.appKeysList.filter(item => item.rank !== 0)
      this.appKeysList = this.filteredAppKeysList.map(item => {
        return {
          app_key: item.app_id,
          name: item.app_name
        }
      })
      this.appKeysNameMap = this.appKeysList.reduce((result, item) => {
        result[item.app_key] = item.name
        return result
      }, {})
    },
    appKeyFormatter (row, column, cellValue, index) {
      const arr = []
      if (cellValue && Array.isArray(cellValue)) {
        cellValue.forEach((item) => {
          if (this.appKeysNameMap[item]) {
            arr.push(this.appKeysNameMap[item])
          }
        })
      }
      return arr.join('、')
    },
    getListData () {
      this.itemInfo.loading = true
      getAdsenseList().then(res => {
        this.itemInfo.list = res.data
      })
        .finally(() => {
          this.itemInfo.loading = false
        })
    },
    // 老数据row没有俩值 给默认值
    handleEditItem (row) {
      // 先深拷贝一下  防止修改元数据（这种深拷贝有缺陷 可以凑合用）
      const data = { ...row }
      for (const i in data.settings) {
        if (!data.settings[i]?.version) data.settings[i].version = [0, 0]
        if (!data.settings[i]?.style) data.settings[i].style = []
      }
      data.app_keys = data.app_keys || []
      data.settings = data.settings || {}
      this.subFormData = data
      console.log(this.subFormData, 'this.subFormData')
      this.dialogVisible = true
    },
    handleClose () {
      this.dialogVisible = false
      this.subFormData = {
        slot: 'search',
        type: 'google',
        status: 2,
        app_keys: [],
        settings: {}
      }
    },
    async createAndUpdateAdsense () {
      try {
        this.addLoading = true
        const { id } = this.subFormData
        if (id) {
          await updateAdsense(id, this.subFormData)
          this.$message.success('更新成功')
        } else {
          await createAdsense(this.subFormData)
          this.$message.success('添加成功')
        }
        this.handleClose()
        this.getListData()
      } catch (error) {
        this.$message.error(error.response.data.message)
      } finally {
        this.addLoading = false
      }
    }
    // handleToggleItem (row) {
    //   const TIPS =
    //     row.status !== 1
    //       ? '此操作将启用广告， 是否继续?'
    //       : '此操作将禁用广告， 是否继续?'
    //   const NEXT_STATUS = row.status !== 1 ? 1 : 2
    //   this.$confirm(TIPS, '提示', { type: 'warning ' }).then(() => {
    //     this.$set(row, 'loading', true)
    //     toggleAdvItem({
    //       id: row.id,
    //       status: NEXT_STATUS
    //     })
    //       .then(res => {
    //         if (res.code === 200) {
    //           this.$set(row, 'status', NEXT_STATUS)
    //         }
    //       })
    //       .finally(() => {
    //         this.$set(row, 'loading', false)
    //       })
    //   })
    // },
    // handleDeleteItem (row) {
    //   this.$confirm('此操作将删除该广告, 是否继续', '提示', {
    //     type: 'warning '
    //   }).then(() => {
    //     this.$set(row, 'deleteLoading', true)
    //     deleteAdvItem({
    //       id: row.id
    //     })
    //       .then(res => {
    //         if (res.code === 200) {
    //           this.$message.success('删除成功')
    //           this.getListData()
    //         }
    //       })
    //       .finally(() => {
    //         this.$set(row, 'deleteLoading', false)
    //       })
    //   })
    // }
  }
}
</script>
