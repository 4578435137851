
import request from '@/utils/request'

export function getAdsenseList (params) {
  return request({
    url: 'admin/adsense',
    params,
    method: 'get',
    baseURL: process.env.VUE_APP_BASEURL_API
  })
}

export function createAdsense (data) {
  return request({
    url: 'admin/adsense',
    data,
    method: 'post',
    baseURL: process.env.VUE_APP_BASEURL_API
  })
}

export function updateAdsense (id, data) {
  return request({
    url: `admin/adsense/${id}`,
    data,
    method: 'put',
    baseURL: process.env.VUE_APP_BASEURL_API
  })
}
